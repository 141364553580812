import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'survey',
  initialState: {
    callToRefreshText: '',
    showCustomTitle: false,
    enrollment: null,
    question: null,
    questionError: null
  },
  reducers: {
    setCallToRefreshText: (state, action) => {
      state.callToRefreshText = action.payload
    },
    setShowCustomTitle: (state, action) => {
      state.showCustomTitle = action.payload
    },
    setEnrollment: (state, action) => {
      state.enrollment = action.payload
    },
    setQuestion: (state, action) => {
      state.question = action.payload
    },
    setQuestionError: (state, action) => {
      state.questionError = action.payload
    },
    setAuthorizationType: (state, action) => {
      state.authorizationType = action.payload
    }
  }
})

export const {
  setCallToRefreshText,
  setShowCustomTitle,
  setEnrollment,
  setQuestion,
  setQuestionError,
  setAuthorizationType
} = slice.actions

export default slice.reducer
